import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SearchForm from './SearchForm';
import TaskList from './TaskList';
import TaskResult from './TaskResult';
import { SERVER_ADDR } from './server';

const App = () => {

  const [serverResponse, setServerResponse] = useState(null); // State to store server response

  const createTask = (type, query) => {

    const currentDateTime = new Date();
    const timestampMs = currentDateTime.toISOString().slice(0, 19).replace('T', ' ');;
    console.log(timestampMs);

    const requestData = {
      user_id: "TEST_USER",
      query: query,
      type: type,
      status: "PENDING",
      timestamp: timestampMs,
      description: ""
    };

    fetch(`${SERVER_ADDR}/create_task`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    })
    .then(response => {
      console.log(response);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      setServerResponse({ data: data }); // Store response and data in state
      console.log(data); // Display a success message
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<div><SearchForm onCreateTask={createTask} /><TaskList /></div>} />
          <Route path="/tasks/:task_uuid" element={<TaskResult />} />
        </Routes>
      </div>
    </Router>
  );

};

export default App;
