import React, { useState, useEffect } from 'react';
import { SERVER_ADDR } from './server'

const TaskList = () => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = () => {
    console.log(SERVER_ADDR);
    fetch(`${SERVER_ADDR}/tasks`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch tasks');
        }
        console.log(response)
        return response.json();
      })
      .then(data => {
        console.log(data)
        setTasks(data);
      })
      .catch(error => {
        console.error('Error fetching tasks:', error);
      });
  };

  return (
    <div>
      <h2>All Tasks In The Past 1h</h2>
      <h3>Refresh to get the task list and result.</h3>
      <ul>
      {tasks.map(task => (
        <li key={`${task.task_uuid}_${task.db_pk}`}>task_uuid = {`${task.task_uuid}`} - Status: {task.status} - Timestamp: {task.timestamp} - Query: {task.query} - Type: {task.type}
        <br></br>
        <a href={`/tasks/${task.task_uuid}`} target="_blank">View Task Result</a>
        </li>
      ))}
      </ul>
    </div>
  );
};

export default TaskList;
