import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SERVER_ADDR } from './server'

const TaskResult = () => {
  const { task_uuid } = useParams();
  const [result, setResult] = useState('');

  useEffect(() => {
    fetch(`${SERVER_ADDR}/tasks/${task_uuid}`)
      .then(response => response.json())
      .then(data => {console.log(data); setResult(data);})
      .catch(error => console.error('Error fetching task result:', error));
  }, [task_uuid]);

  return (
    <div>
      <h2>Task Result</h2>
      <pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(result, null, 2)}</pre>
    </div>
  );
};

export default TaskResult;
