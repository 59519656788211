import React, { useState } from 'react';
import './SearchForm.css';

const SearchForm = ({ onCreateTask, serverResponse }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (type, event) => {
    event.preventDefault();
    onCreateTask(type, searchQuery.trim())
    console.log('Search query:', searchQuery)
    console.log('Type:', type)
    window.location.reload(); // Refresh the page
  };

  return (
    <div className="search-container">
      <div className="search-title">Twitter Search</div>
      <form className="search-form">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={(e) => handleSearch('USER', e)}>Search User</button>
        <button onClick={(e) => handleSearch('KEYWORD', e)}>Search Keyword</button>
      </form>
    {/* Render server response if available */}
        {serverResponse && (
        <div className="server-response">
          <h2>Server Response</h2>
          <p>Data: {JSON.stringify(serverResponse.data)}</p>
        </div>
      )}
    </div>
  );
};

export default SearchForm;
